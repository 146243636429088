import { Workshop } from '@/types/app/workshop';
import Image from '@/components/image';
import { cn } from '@/lib/utils';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import Button from '@/components/button'
import { Play, Star } from 'lucide-react';
import {
    WorkshopCardType,
    WorkshopEditableCard, WorkshopMediaCard,
    WorkshopMiniCard,
    WorkshopTimelineCard
} from '@/components/app/workshop/cards/cards';
import { WorkshopBareCard, WorkshopCardProps, WorkshopDefaultCard } from '@/components/app/workshop/cards/cards';
import routes from '@/lib/routes';
import { Link } from '@inertiajs/react';

const categoryColors = {
    programming: "from-blue-500 to-blue-700",
    design: "from-purple-500 to-purple-700",
    business: "from-amber-500 to-amber-700",
    marketing: "from-green-500 to-green-700",
    data: "from-rose-500 to-rose-700",
}
export function FeaturedWorkshopHero({ workshop }: {workshop: Workshop}) {
    const category = workshop.category?.name;

    return (
        <div className="relative overflow-hidden rounded-xl">
            <div className="relative aspect-[21/9] w-full overflow-hidden sm:aspect-[16/9] md:aspect-[21/9]">
                <Image
                    src={workshop.cover_image || "/placeholder.svg"}
                    alt={workshop.title}
                    fill
                    priority
                    className="object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-r from-black/80 via-black/60 to-transparent"></div>
            </div>

            <div className="absolute inset-0 flex flex-col justify-center p-4 sm:p-6 md:p-8 lg:p-12">
                <div className="max-w-2xl">
                    <div
                        className={cn(
                            "mb-2 w-fit rounded-md bg-gradient-to-r px-2 py-1 text-xs font-medium text-white",
                            categoryColors[category as keyof typeof categoryColors] || "from-gray-500 to-gray-700",
                        )}
                    >
                        {category.charAt(0).toUpperCase() + category.slice(1)}
                    </div>
                    <h1 className="mb-2 text-xl font-bold tracking-tight text-white sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl">
                        {workshop.title}
                    </h1>
                    <p className="mb-4 max-w-lg text-sm text-white/80 sm:text-base md:text-lg">{workshop.excerpt}</p>

                    {workshop.instructor && (

                    <div className="mb-4 flex items-center gap-3 sm:mb-6">
                        <Avatar className="h-8 w-8 border-2 border-primary sm:h-10 sm:w-10">
                            <AvatarImage src={workshop.instructor.avatar_url} alt={workshop.instructor.name} />
                            <AvatarFallback>{workshop.instructor.name.charAt(0)}</AvatarFallback>
                        </Avatar>
                        <div>
                            <p className="font-medium text-white text-sm sm:text-base">{workshop.instructor.name}</p>
                            <p className="text-xs text-white/70 sm:text-sm">{workshop.instructor.job_title}</p>
                        </div>
                    </div>
            )}

                    <div className="flex flex-wrap gap-3">
                        <Button size="sm" className="gap-2 sm:size-md">
                            <Play className="h-4 w-4" fill="currentColor" />
                            Watch Preview
                        </Button>
                        <Button size="sm" variant="outline" className="bg-white/10 text-white hover:bg-white/20 sm:size-md">
                            Enroll Now
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}



export function WorkshopCard(
    {
        workshop,
        type = 'default',
        useLink = true,
        ...props }: WorkshopCardProps
) {
    const workshopTypes: Record<WorkshopCardType, React.FC<{ workshop: Workshop }>> = {
        default: WorkshopDefaultCard,
        media: WorkshopMediaCard,
        bare: WorkshopBareCard,
        timeline: WorkshopTimelineCard,
        mini: WorkshopMiniCard,
        editable: WorkshopEditableCard,
    };

    // If type is not in workshopTypes, default to 'default'
    const SelectedComponent = workshopTypes[type] || WorkshopDefaultCard;

    return useLink ?
            <Link href={routes.workshop.show(workshop)}>
                <SelectedComponent workshop={workshop} {...props} />
            </Link> :
        <SelectedComponent workshop={workshop} {...props} />;
}
