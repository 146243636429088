import React from "react";
import { useState } from 'react';
import { cn } from '@/lib/utils';
import Link from '@/components/link';
import routes from '@/lib/routes';
import Image from '@/components/image';
import { Badge } from '@/components/ui/badge';
import { Download, ExternalLink, MapPin, Play, Star, Users } from 'lucide-react';
import { Workshop } from '@/types/app/workshop';
import { Card, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { format, parseISO } from "date-fns"
import { Progress } from '@/components/ui/progress';
import { Calendar, Clock, MoreHorizontal, } from "lucide-react"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import schedule from "../instructor/workshop-form/schedule";
import { formatDate } from "@/lib/date-utils";
import { WorkshopPrice } from "../workshop-components";



const categoryColors = {
    programming: "from-blue-500 to-blue-700",
    design: "from-purple-500 to-purple-700",
    business: "from-amber-500 to-amber-700",
    marketing: "from-green-500 to-green-700",
    data: "from-rose-500 to-rose-700",
}

export type WorkshopCardSize = "small" | "medium" | "large";

export type WorkshopCardAspectRatio = "portrait" | "square" | "video";

export type WorkshopCardType = "media" | "default" | "bare" | "timeline" | "mini" | "editable";

export interface WorkshopCardProps {
    workshop: Workshop
    size?: WorkshopCardSize
    aspectRatio?: WorkshopCardAspectRatio
    width?: number
    showInstructor?: boolean,
    showCoverImage?: boolean,
    type?: WorkshopCardType,
    useLink?: boolean,
    status?: "upcoming" | "active" | "live" | "completed" | "past",
    view?: "grid" | "list",
}

export function WorkshopMediaCard({
                                      workshop,
                                      size = "medium",
                                      aspectRatio = "portrait",
                                      width,
                                      showInstructor = true,
                                  }: WorkshopCardProps) {
    const [isHovered, setIsHovered] = useState(false)

    const category = workshop.category?.name;

    const aspectRatioClass = {
        portrait: "aspect-[3/4]",
        square: "aspect-square",
        video: "aspect-video",
    }

    const sizeClass = {
        small: "w-[180px]",
        medium: "w-[220px]",
        large: "w-[280px]",
    }

    return (
        <div
            className={cn(
                "group relative shrink-0 cursor-pointer rounded-md transition-all bg-black duration-300",
                width ? `w-[${width}px]` : sizeClass[size],
            )}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <Link href={`${routes.workshop.show(workshop)}`} className="block">
                <div
                    className={cn(
                        "overflow-hidden rounded-md bg-muted/30",
                        aspectRatioClass[aspectRatio],
                        isHovered && "ring-2 ring-primary ring-offset-2 ring-offset-background",
                    )}
                >
                    <div className="relative h-full w-full">
                        <Image
                            src={workshop.cover_image || "/placeholder.svg"}
                            alt={workshop.title}
                            fill
                            className={cn("object-cover transition-transform duration-500 h-full", isHovered && "scale-105")}
                        />
                        <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/40 to-transparent"></div>

                        <WorkshopPills workshop={workshop} />
                        {/* Play button overlay */}
                        <div
                            className={cn(
                                "absolute inset-0 flex items-center justify-center opacity-0 transition-opacity duration-300",
                                isHovered && "opacity-100",
                            )}
                        >
                            {/*<div className="rounded-full bg-primary p-3 shadow-lg">*/}
                            {/*    <Play className="h-6 w-6 text-primary-foreground" fill="currentColor" />*/}
                            {/*</div>*/}
                        </div>

                        {/* Category badge */}
                        {category && (
                            <div className="absolute left-2 top-2">
                                <div
                                    className={cn(
                                        "rounded-md bg-gradient-to-r px-2 py-1 text-xs font-medium text-white",
                                        categoryColors[category as keyof typeof categoryColors] || "from-gray-500 to-gray-700",
                                    )}
                                >
                                    {category.charAt(0).toUpperCase() + category.slice(1)}
                                </div>
                            </div>
                        )}

                        {/* Special badge (New, Bestseller, etc.) */}
                        {workshop.badge && (
                            <div className="absolute right-2 top-2">
                                <Badge variant="secondary" className="bg-primary/90 text-primary-foreground hover:bg-primary">
                                    {workshop.badge}
                                </Badge>
                            </div>
                        )}

                        {/* Bottom text overlay */}
                        <div className="absolute bottom-0 left-0 right-0 p-3">
                            <h3 className="line-clamp-2 text-base font-bold text-white">{workshop.title}</h3>
                            {showInstructor && workshop.instructor &&  <p className="mt-1 text-sm text-white/80">{workshop.instructor.name}</p>}
                            {/* <div className="mt-1 flex items-center gap-2">
                                <div className="flex items-center">
                                    <Star className="h-3.5 w-3.5 text-yellow-400 fill-yellow-400" />
                                    <span className="ml-1 text-xs font-medium text-white">{workshop.rating}</span>
                                </div>
                                <span className="text-xs text-white/70">•</span>
                                <span className="text-xs text-white/70">{workshop.participants_count} learners</span>
                            </div> */}
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    )
}


export const WorkshopDefaultCard: React.FC<WorkshopCardProps> = ({ workshop, showCoverImage = true } : WorkshopCardProps) => {

    return (
        <Card className="w-full h-full bg-gradient-to-t to-pink-50 dark:to-pink-900/10 max-w-sm rounded-2xl shadow-none overflow-hidden  p-3 ">
            <div className="snap-start flex-shrink-0 ">
            <div className="group block">
                {workshop.cover_image && showCoverImage && (
                    <div className="relative aspect-[4/3] overflow-hidden rounded-xl">
                        <Image
                            alt={workshop.title}
                            src={workshop.cover_image || "/placeholder.svg?height=400&width=600"}
                            fill
                            className="object-cover bg-black h-full w-full transition-transform duration-300 group-hover:scale-105"
                        />
                        <div className="absolute inset-0 bg-gradient-to-t from-black/70 via-black/30 to-transparent"></div>

                        <WorkshopPills workshop={workshop} />

                        {/* Price Tag */}
                        <div className="absolute bottom-2 right-2">
                            <div className="rounded-md bg-background/90 px-2 py-1 text-sm font-bold">
                                <WorkshopPrice workshop={workshop}/>
                            </div>
                        </div>
                    </div>
                )}

                <div className="mt-3 justify-between flex flex-col">
                    {/* Title */}
                    <h3 className="font-semibold line-clamp-3 group-hover:text-primary transition-colors">
                        {workshop.title}
                    </h3>


                    {/* Instructor */}
                    <p className="text-sm text-muted-foreground mt-1">
                        {workshop.instructor?.name}
                    </p>

                    {/* Rating and Student Count */}
                    {/* <div className="flex items-center gap-1 mt-1">
                        <div className="flex items-center">
                            <Star className="h-4 w-4 text-yellow-400 fill-yellow-400" />
                            <span className="ml-1 text-sm font-medium">{workshop.rating}</span>
                        </div>
                        <span className="text-xs text-muted-foreground">•</span>
                        <span className="text-xs text-muted-foreground">{workshop.participants_count} students</span>
                    </div> */}
                </div>
            </div>
        </div>
        </Card>
    );
};

// export const WorkshopDefaultCard: React.FC<WorkshopCardProps> = ({ workshop, showCoverImage = true }) => {
//     return (
//         <Card className="w-full max-w-sm rounded-2xl shadow-none overflow-hidden  p-3 ">
//             {/* Cover Image */}
//             {workshop.cover_image && showCoverImage && (
//                 <div className="relative w-full h-48 overflow-hidden rounded-lg">
//                     <Image
//                         src={workshop.cover_image}
//                         alt={workshop.title}
//                         layout="fill"
//                         objectFit="cover"
//                         className="hue-rotate-15 h-full w-full object-cover"
//                     />
//                 </div>
//             )}
//
//             <CardContent className=" p-3 pt-0 space-y-3">
//                 {/* Title */}
//                 <h3 className="text-lg font-semibold text-gray-900">
//                     {workshop.title}
//                 </h3>
//
//                 {/* Excerpt */}
//                 <p className="text-sm text-gray-600">{workshop.excerpt}</p>
//
//                 {/* Rating & Difficulty */}
//                 <div className="flex items-center justify-between text-sm text-gray-700">
//                     <div className="flex items-center gap-1">
//                         <Star className="w-4 h-4 text-yellow-500" />
//                         <span>{workshop.rating} ({workshop.reviewCount} reviews)</span>
//                     </div>
//                     <span className="bg-gray-200 text-gray-800 px-2 py-1 rounded text-xs">
//             {workshop.difficulty_level}
//           </span>
//                 </div>
//
//                 {/* Instructor */}
//                 {workshop.instructor && (
//                  <div className="flex items-center gap-3 mt-3">
//                     <Image
//                         src={workshop.instructor.cover_image || "/default-avatar.png"}
//                         alt={workshop.instructor.name}
//                         width={36}
//                         height={36}
//                         className="rounded-full"
//                     />
//                     <div>
//                         <p className="text-sm font-medium text-gray-900">
//                             {workshop.instructor.name}
//                         </p>
//                         <p className="text-xs text-gray-600">
//                             {workshop.instructor.position}
//                         </p>
//                     </div>
//                 </div>
//                 )}
//                 {/* CTA Button */}
//                 <Button className="w-full mt-4">View Workshop</Button>
//             </CardContent>
//         </Card>
//     );
// };

export const WorkshopBareCard: React.FC<WorkshopCardProps> = ({ workshop }) => {
    return (
       <WorkshopDefaultCard workshop={workshop} showCoverImage={false} />
    );
};




export function WorkshopTimelineCard({ workshop, status, type }: WorkshopCardProps) {
    const dateObj = parseISO(workshop.start_time)
    const formattedTime = format(dateObj, "h:mm a")

    const statusColors = {
        upcoming:
            "bg-blue-500/10 text-blue-600 border-blue-200 dark:border-blue-800 dark:bg-blue-500/20 dark:text-blue-400",
        active:
            "bg-green-500/10 text-green-600 border-green-200 dark:border-green-800 dark:bg-green-500/20 dark:text-green-400",
        completed:
            "bg-gray-500/10 text-gray-600 border-gray-200 dark:border-gray-800 dark:bg-gray-500/20 dark:text-gray-400",
    }

    const statusText = {
        upcoming: "Upcoming",
        active: "In Progress",
        completed: "Completed",
    }

    return (
        <Card className="group overflow-hidden border-border/50 transition-all duration-300 hover:shadow-md py-0">
            <div className="flex ">


                <div className="p-4 flex-1">
                    <div className="text-sm text-muted-foreground mb-1">{formattedTime}</div>
                    <h3 className="text-lg font-bold group-hover:text-primary transition-colors mb-2">{workshop.title}</h3>

                    <div className="flex flex-wrap gap-3 mb-3">
                        <Badge className={cn("border", statusColors[type])}>{statusText[type]}</Badge>

                        {status === "active" && workshop.nextSession && (
                            <Badge variant="outline" className="bg-primary/5 text-primary border-primary/20">
                                Next Session: {format(parseISO(workshop.nextSession.date), "MMM d")}
                            </Badge>
                        )}
                    </div>

                    {workshop.instructor && (
                        <div className="space-y-1.5 mb-4">
                            <div className="flex items-center text-sm">
                                <Users className="mr-2 h-4 w-4 text-muted-foreground" />
                                <span>Instructor: {workshop.instructor.name}</span>
                            </div>
                        </div>
                    )}

                    {status === "active" && (
                        <div className="mb-4 space-y-2">
                            <div className="flex items-center justify-between text-sm">
                                <span>Progress</span>
                                <span className="font-medium">{workshop.progress}%</span>
                            </div>
                            <Progress value={workshop.progress} className="h-2" />
                        </div>
                    )}

                    <div className="flex flex-wrap gap-2">
                        <Button variant="default" size="sm" asChild>
                            <Link href={routes.workshop.enrolled.show(workshop)}>View Details</Link>
                        </Button>

                        {status !== "past" && (
                            <Button variant="outline" size="sm" asChild>
                                <Link href={routes.workshop.join(workshop)} target="_blank" rel="noopener noreferrer">
                                    <ExternalLink className="mr-2 h-3.5 w-3.5" />
                                    Join
                                </Link>
                            </Button>
                        )}

                        {/*{type === "past" && workshop.certificate?.issued && (*/}
                        {/*    <Button variant="outline" size="sm">*/}
                        {/*        <Download className="mr-2 h-3.5 w-3.5" />*/}
                        {/*        Download Certificate*/}
                        {/*    </Button>*/}
                        {/*)}*/}
                    </div>
                </div>
                <div className="relative w-1/3 m-3 max-h-40 overflow-hidden rounded-lg ">
                    <div className="relative overflow-hidden rounded-lg ">
                        <Image
                            src={workshop.cover_image || "/placeholder.svg"}
                            alt={workshop.title}
                            fill
                            className="object-cover w-full h-full transition-transform duration-500 group-hover:scale-105"
                        />

                    </div>
                </div>
            </div>
        </Card>
    )
}


export const WorkshopMiniCard = ({ workshop } : { workshop: Workshop }) => {
    const category = workshop.category?.name;

    return (
        <div className="flex w-[300px] items-center gap-3 rounded-md p-2 transition-colors hover:bg-muted/50">
            <div className="relative h-[60px] w-[60px] overflow-hidden rounded-md">
                <Image
                    src={workshop.cover_image || "/placeholder.svg"}
                    alt={workshop.title}
                    fill
                    className="object-cover"
                />

            </div>
            <div className="flex-1 min-w-0">
                <div className="flex items-center gap-2">
                    <h3 className="font-medium line-clamp-1 group-hover:text-primary transition-colors">
                        {workshop.title}
                    </h3>
                    {workshop.featured && (
                        <Badge
                            variant="outline"
                            className="bg-primary/10 text-primary border-primary/20 text-[10px] h-4 px-1"
                        >
                            Featured
                        </Badge>
                    )}
                </div>
                <div className="flex items-center gap-1 text-sm text-muted-foreground">
                    <span className="truncate">{workshop.instructor?.name}</span>
                    <span>•</span>
                    <Badge
                        variant="outline"
                        className={cn(
                            "text-[10px] h-4 px-1 capitalize",
                            categoryColors[category as keyof typeof categoryColors],
                        )}
                    >
                        {category}
                    </Badge>
                </div>
                {/*<div className="flex items-center gap-1 text-xs text-muted-foreground">*/}
                {/*    <Star className="h-3 w-3 text-yellow-400 fill-yellow-400" />*/}
                {/*    <span>4.8</span>*/}
                {/*    <span>•</span>*/}
                {/*    <span>{workshop.views} views</span>*/}
                {/*</div>*/}
            </div>
        </div>
    )
}


const WorkshopPills = ({ workshop }: { workshop: Workshop }) => {
    // const category = workshop.category?.name;

    // Get gradient colors based on category
    const getCategoryGradient = () => {
        switch (workshop.category?.name.toLowerCase()) {
            case 'programming':
                return 'from-blue-500 to-blue-700';
            case 'design':
                return 'from-purple-500 to-purple-700';
            case 'data':
                return 'from-rose-500 to-rose-700';
            case 'business':
                return 'from-amber-500 to-amber-700';
            case 'marketing':
                return 'from-green-500 to-green-700';
            default:
                return 'from-pink-500 to-pink-700';
        }
    };


    return (
        <>
            {/* Category Tag */}
            {workshop.category && (
                <div className="absolute left-2 top-2">
                    <div className={`rounded-md bg-gradient-to-r px-2 py-1 text-xs font-medium text-white ${getCategoryGradient()}`}>
                        {workshop.category?.name}
                    </div>
                </div>
            )}

            {/* Badge (Bestseller, New, Popular) */}
            {workshop.badge && (
                <div className="absolute right-2 top-2">
                    <div className="inline-flex items-center rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent bg-primary/90 text-primary-foreground hover:bg-primary">
                        {workshop.badge.text}
                    </div>
                </div>
            )}
        </>
    )
}


export function WorkshopEditableCard({ workshop, view }: WorkshopCardProps) {
    const statusColors = {
      scheduled:
        "bg-blue-500/10 text-blue-600 border-blue-200 dark:border-blue-800 dark:bg-blue-500/20 dark:text-blue-400",
      live:
        "bg-green-500/10 text-green-600 border-green-200 dark:border-green-800 dark:bg-green-500/20 dark:text-green-400",
      completed:
        "bg-gray-500/10 text-gray-600 border-gray-200 dark:border-gray-800 dark:bg-gray-500/20 dark:text-gray-400",
    }

    const statusText = {
      upcoming: "Upcoming",
      active: "Active",
      completed: "Completed",
    }

    const participationPercentage = ((workshop.participants_count ?? 0) / (workshop.max_participants ?? 1)) * 100
    const isFull = (workshop.participants_count ?? 0) >= (workshop.max_participants ?? 0)

    if (view === "grid") {
      return (
        <div className="group relative overflow-hidden rounded-xl transition-all duration-300 hover:shadow-lg">
          {/* Image with overlay */}
          <div className="relative aspect-square overflow-hidden">
            <Image
              src={workshop.cover_image || "/placeholder.svg"}
              alt={workshop.title}
              fill
              className="object-cover h-full w-full transition-transform duration-500 group-hover:scale-105"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/40 to-transparent opacity-100"></div>

            {/* Play button overlay */}
            {/* <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
              <div className="rounded-full bg-white/90 p-3 shadow-lg">
                <svg className="h-8 w-8 text-primary" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M8 5v14l11-7z" />
                </svg>
              </div>
            </div> */}

            {/* Status badge */}
            {/*<Badge className={cn("absolute top-3 right-3 border", statusColors[workshop.status as keyof typeof statusColors] || "bg-gray-500/10")}>*/}
            {/*  {statusText[workshop.status as keyof typeof statusText] || "Unknown Status"}*/}
            {/*</Badge>*/}
              <Badge variant="outline">
                  {workshop.status?.charAt(0).toUpperCase() + workshop.status.slice(1)}
              </Badge>

            {/* Category badge */}
            <Badge
              className={cn(
                "absolute top-3 left-3 border",
                categoryColors[workshop.category?.name as unknown as keyof typeof categoryColors] || "bg-gray-500/10",
              )}
            >
              {workshop.category?.name}
            </Badge>

            {/* Bottom text overlay */}
            <div className="absolute bottom-0 left-0 right-0 p-4 text-white">
              <h3 className="font-bold text-lg line-clamp-1 mb-1">{workshop.title}</h3>
              <p className="text-sm text-white/80">by {workshop.instructor?.name}</p>
            </div>
          </div>

          {/* Card content */}
          <div className="p-4 bg-card border-x border-b rounded-b-xl border-border/50">
            <div className="flex justify-between items-center mb-3">
              <div className="flex items-center text-sm text-muted-foreground">
                <Calendar className="mr-2 h-4 w-4" />
                {workshop.start_time && formatDate(workshop.start_time)}
              </div>
              <div className="text-lg font-bold text-primary"><WorkshopPrice workshop={workshop}/></div>
            </div>

            <div className="space-y-3">
              <div className="flex items-center text-sm text-muted-foreground">
                <Clock className="mr-2 h-4 w-4" />
                {workshop.duration} hour(s)
              </div>

              <div className="space-y-1">
                <div className="flex items-center justify-between text-sm">
                  <div className="flex items-center">
                    <Users className="mr-2 h-4 w-4" />
                    <span>Enrollment</span>
                  </div>
                  <span className={cn("font-medium", isFull ? "text-destructive" : "text-primary")}>
                    {workshop.participants_count}/{workshop.max_participants}
                  </span>
                </div>
                <Progress
                  value={participationPercentage}
                  className={cn("h-1.5", isFull ? "bg-destructive" : "bg-primary")}
                />
              </div>
            </div>

            <div className="flex w-full gap-2 mt-4">
              <Button
                variant="outline"
                size="sm"
                className="flex-1 border-primary/20 text-primary hover:bg-primary/5"
                asChild
              >
                <Link href={routes.instructor.workshop(workshop)}>View Details</Link>
              </Button>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline" size="icon" className="border-primary/20 text-primary hover:bg-primary/5">
                    <MoreHorizontal className="h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuLabel>Actions</DropdownMenuLabel>
                  <DropdownMenuItem asChild>
                    <Link href={routes.instructor.workshop(workshop)}>View Details</Link>
                  </DropdownMenuItem>
                  <DropdownMenuItem asChild>
                    <Link href={routes.instructor.workshops.edit(workshop)}>Edit Workshop</Link>
                  </DropdownMenuItem>
                  <DropdownMenuSeparator />
                  {/* <DropdownMenuItem>Manage Participants</DropdownMenuItem> */}
                  {/* <DropdownMenuItem>Send Notification</DropdownMenuItem> */}
                  <DropdownMenuSeparator />
                  {/* <DropdownMenuItem className="text-destructive">Cancel Workshop</DropdownMenuItem> */}
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
        </div>
      )
    }

    // List view
    return (
      <div className="group relative overflow-hidden rounded-xl transition-all duration-300 hover:shadow-lg border border-border/50 bg-card">
        <div className="flex flex-col md:flex-row">
          <div className="relative md:w-64 h-48 md:h-auto">
            <Image
              src={workshop.image || "/placeholder.svg"}
              alt={workshop.title}
              fill
              className="object-cover transition-transform duration-500 group-hover:scale-105"
            />
            <div className="absolute inset-0 bg-gradient-to-r from-black/60 to-transparent md:bg-gradient-to-t"></div>

            {/* Play button overlay */}
            <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
              <div className="rounded-full bg-white/90 p-3 shadow-lg">
                <svg className="h-8 w-8 text-primary" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M8 5v14l11-7z" />
                </svg>
              </div>
            </div>

            {/* Status badge */}
            <Badge className={cn("absolute top-3 right-3 border", statusColors[workshop.status])}>
              {statusText[workshop.status]}
            </Badge>

            {/* Category badge */}
            <Badge
              className={cn(
                "absolute top-3 left-3 border",
                categoryColors[workshop.category as keyof typeof categoryColors] || "bg-gray-500/10",
              )}
            >
              {workshop.category}
            </Badge>
          </div>

          <div className="flex flex-col flex-1 p-4">
            <div className="flex justify-between items-start">
              <div>
                <h3 className="text-xl font-bold group-hover:text-primary transition-colors">{workshop.title}</h3>
                <p className="text-sm text-muted-foreground">by {workshop.instructor.name}</p>
              </div>
              <div className="text-lg font-bold text-primary"><WorkshopPrice workshop={workshop}/></div>
            </div>

            <p className="line-clamp-2 text-sm text-muted-foreground my-2">{workshop.description}</p>

            <div className="flex flex-wrap gap-4 my-2">
              <div className="flex items-center text-sm">
                <Calendar className="mr-2 h-4 w-4 text-muted-foreground" />
                {workshop.date}
              </div>
              <div className="flex items-center text-sm">
                <Clock className="mr-2 h-4 w-4 text-muted-foreground" />
                {workshop.time}
              </div>
              <div className="flex items-center text-sm">
                <Users className="mr-2 h-4 w-4 text-muted-foreground" />
                <span className={cn("font-medium", isFull ? "text-destructive" : "text-primary")}>
                  {workshop.participants}/{workshop.capacity}
                </span>
              </div>
            </div>

            <div className="mt-auto pt-2 flex justify-between items-center">
              <div className="w-32">
                <Progress
                  value={participationPercentage}
                  className="h-1.5"
                  indicatorClassName={cn(isFull ? "bg-destructive" : "bg-primary")}
                />
              </div>
              <div className="flex gap-2">
                <Button variant="outline" size="sm" className="border-primary/20 text-primary hover:bg-primary/5" asChild>
                  <Link href={`/instructor/dashboard/workshops/${workshop.id}`}>View Details</Link>
                </Button>
                <Button variant="outline" size="sm" className="border-primary/20 text-primary hover:bg-primary/5" asChild>
                  <Link href={`/instructor/dashboard/workshops/edit/${workshop.id}`}>Edit</Link>
                </Button>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button
                      variant="outline"
                      size="icon"
                      className="h-8 w-8 border-primary/20 text-primary hover:bg-primary/5"
                    >
                      <MoreHorizontal className="h-4 w-4" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end">
                    <DropdownMenuLabel>Actions</DropdownMenuLabel>
                    <DropdownMenuItem>Manage Participants</DropdownMenuItem>
                    <DropdownMenuItem>Send Notification</DropdownMenuItem>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem className="text-destructive">Cancel Workshop</DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
